import TYPES from './note.types';
import noteReducer from './note.reducer';

const INITIAL_STATE = {
    noteContent: "",
    creadtedAt: "",
    jobId: ""
};

export const NOTE_STATE = {
    types: TYPES,
    initialState: INITIAL_STATE,
    reducer: noteReducer,
};
