import TYPES from './leads.types';
import reducer from './leads.reducer';

const INITIAL_STATE = {
    leads : []
};

const LEADS_STATE = {
    types: TYPES,
    initialState: INITIAL_STATE,
    reducer,
};

export default LEADS_STATE;