import React, { useContext, useEffect, useState } from 'react';
// reactstrap components
import { Button, Card, CardHeader, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown, Progress, Table, Container, Row, Col } from 'reactstrap';
// core components
import CardsHeader from 'components/cards/card-header';
import { AppContext } from 'store/app.context';
import ModalAddProject from 'components/ModalAddProjet/ModalAddProjet';
import useFocusService from 'services/focus/focus.service';
import ModalMainInfo from 'components/modalmaininfo/ModalMainInfo';
import CardNotifications from 'components/cards/card-notification';
import CardsFocus from 'components/cards/card-focus';
import useHomeService from 'services/home/home.service';
import List from 'list.js';
import moment from 'moment';
import ModalEditprojet from 'components/ModalAddProjet/ModalEditprojet';
import useMissionService from 'services/missions/mission.service';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../../components/confirmation-modal/confirmationModal';
import APP_ROUTES from 'navigation/app.routes';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const Dashboard = () => {
  const history = useHistory();
  const location = useLocation();
  const { languageState, missionState, homeState } = useContext(AppContext);
  const { getMisions } = useFocusService();
  const { archiveMission, deleteMission } = useMissionService();
  const { getQuickLinks, getFeed } = useHomeService();
  const [modalShow, setModalShow] = useState(false);
  const [modalEditShow, setModalEditShow] = useState(false);
  const [modalInfoShow, setModalInfoShow] = useState(false);
  const [mainInfo] = useState('');
  const [projects, setProjects] = useState([]);
  const projectTable = React.useRef(null);
  const [projet, setProjet] = useState({});
  const [projectId, setProjectId] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [priorityReverseSort, setPriorityReverseSort] = useState(false);
  const [deadLineProjectReverseSort, setDeadLineProjectReverseSort] = useState(false);

  useEffect(() => {
    getMisions();
    getQuickLinks();
    getFeed();
  }, []);

  useEffect(() => {
    if (location.search === "?add-project") {
      setModalShow(true)
    }

  }, [location])

  const sortProjectByPriority = (shouldBeReversed) => {
    if (projects.length > 1) {
      const highPriorityProject = [
        ...projects.filter((project) => project.priority === 'High'),
      ];
      const mediumPriorityProject = [
        ...projects.filter((project) => project.priority === 'Medium'),
      ];
      const lowPriorityProjects = [
        ...projects.filter(
          (project) => project.priority === 'Low' || project.priority === ''
        ),
      ];
      const newProjects = [
        ...highPriorityProject,
        ...mediumPriorityProject,
        ...lowPriorityProjects,
      ];

      setProjects(shouldBeReversed ? newProjects.reverse() : newProjects);
    }
  };
  const sortCallback = (a, b) => {
    if (new Date(a.endDate) > new Date(b.endDate)) return 1;
    else if (new Date(a.endDate) < new Date(b.endDate)) return -1;
    else return 0;
  };

  const sortProjectByDeadLine = (shouldBeReversed) => {
    if (projects.length > 1) {
      const newProjects = [...projects];
      if (shouldBeReversed) {
        setProjects(newProjects.sort(sortCallback).reverse());
      } else {
        setProjects(newProjects.sort(sortCallback));
      }
    }
  };

  useEffect(() => {
    if (missionState.missions)
      setProjects(
        missionState.missions.filter((data) => data.type === 'PROJET')
      );
  }, [missionState.missions]);
  useEffect(() => {
    if (projects.length > 0) {
      // if (projects)
      new List(projectTable.current, {
        valueNames: ['projet', 'priority'],
        listClass: 'list',
      });
    }
  }, [projects]);

  useEffect(() => {
    sortProjectByPriority(priorityReverseSort);
  }, [priorityReverseSort]);

  useEffect(() => {
    sortProjectByDeadLine(deadLineProjectReverseSort);
  }, [deadLineProjectReverseSort]);

  const calculProgress = (startDate, endDate) => {
    const today = moment(new Date());
    let date1 = moment(endDate);
    let date2 = moment(startDate);
    let date = date1.diff(date2, 'days'); // =1
    //check wether the start date is higher than today
    if (date2.diff(today, 'days') > 0) {
      return 0;
    }

    if (date > 0) {
      let date3 = moment(new Date());
      let dateReaming = date1.diff(date3, 'days'); // =1
      if (dateReaming <= 0) {
        return 100;
      }
      return (((date - dateReaming) / date) * 100)?.toFixed(0); // =1
    }
  };
  const archive = async () => {
    const result = await archiveMission(projectId, true);
    if (result && result.status === 200) {
      await getMisions();

      toast.success(languageState.translation.HOME.DASHBOARD.MISSION_ARCHIVED, {
        position: 'bottom-right',
        hideProgressBar: false,
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      setShowConfirmation(false);
    }
  };
  const deleteMissionFN = async (id) => {
    const result = await deleteMission(id);
    if (result && result.status === 200) {
      await getMisions();

      toast.success(languageState.translation.HOME.DASHBOARD.MISSION_DELETED, {
        position: 'bottom-right',
        hideProgressBar: false,
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };
  return (
    <>
      <CardsHeader
        name={languageState.translation.COMMON.DEFAULT}
        parentName={languageState.translation.COMMON.DASHBOARDS}
      />
      <Container className="mt-4" fluid>
        <h2 className="mb-3">{languageState.translation.COMMON.DAILY_LINKS}</h2>

        <div className="parent home-link  mb-3 ">
          {homeState &&
            homeState.quickLinks.map((tool, index) => (
              <CardsFocus
                name={tool.name}
                key={tool.id}
                category={tool.category}
                link={tool.link}
                id={tool?.id}
              />
            ))}
          <CardsFocus name="AJOUTER NOUVEAU SHORTCUT" idproject={''} />
        </div>
        <Row>
          {' '}
          <Col xl="12">
            <Row className="h-100 pb-4">
              <div className="col h-100 ">
                <Card className="h-100  ">
                  <CardHeader className="border-0 d-flex align-items-center justify-content-between">
                    <h3 className="mb-0">
                      {
                        languageState.translation.HOME.DASHBOARD.PROJECT_OVERVIEW
                      }
                    </h3>
                    <Button
                      className="btn-exprimary"
                      size="sm"
                      onClick={() => setModalShow(true)}
                    >
                      {languageState.translation.HOME.DASHBOARD.ADD_PROJECT}
                    </Button>
                  </CardHeader>
                  <div
                    className="table-responsive h-100 custom-height"
                    ref={projectTable}
                  >
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="sort" data-sort="projet" scope="col">
                            {languageState.translation.COMMON.PROJECT}
                          </th>
                          <th
                            data-sort="priority"
                            scope="col"
                            onClick={() => {
                              setPriorityReverseSort((prevState) => !prevState);
                            }}
                          >
                            {languageState.translation.COMMON.PRIORITY}
                          </th>

                          <th className="sort">
                            {languageState.translation.COMMON.PROGRESS}
                          </th>
                          <th className="">
                            {languageState.translation.COMMON.COMPANY}
                          </th>
                          <th
                            className=""
                            data-sort="priority"
                            scope="col"
                            onClick={() => {
                              setDeadLineProjectReverseSort(
                                (prevState) => !prevState
                              );
                            }}
                          >
                            {languageState.translation.COMMON.DEADLINE}
                          </th>
                          <th className="">
                            {languageState.translation.CHAT.ACTION}
                          </th>

                          {/* <th scope="col" /> */}
                        </tr>
                      </thead>
                      <tbody className="list h-100">
                        {projects &&
                          projects.map((data, index) => (
                            <tr key={index}>
                              <td
                                scope="row"
                                style={{
                                  cursor: 'pointer',
                                }}
                        /*         onClick={() => {
                                  history.push(
                                    '/private' +
                                    APP_ROUTES.FOCUS.DASHBOARD +
                                    '/' +
                                    data.id
                                  );
                                }} */
                              >
                                <span className="name mb-0 projet fs-3">
                                  {data.name}
                                </span>
                              </td>
                              <td
                                className="priority"
                                onClick={() => {
                                  // setmainInfo(data.mainInfo);
                                  // setModalInfoShow(true);
                                }}
                              >
                                {data?.priority ? data.priority : languageState.translation.COMMON.LOW}
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span className="completion mr-2">
                                    {' '}
                                    {calculProgress(
                                      data?.startDate,
                                      data?.endDate
                                    )}
                                    %
                                  </span>
                                  <div>
                                    <Progress
                                      max="100"
                                      value={calculProgress(
                                        data?.startDate,
                                        data?.endDate
                                      )}
                                      color={
                                        calculProgress(
                                          data?.startDate,
                                          data?.endDate
                                        ) == 100
                                          ? 'success'
                                          : 'warning'
                                      }
                                    />
                                  </div>
                                </div>
                              </td>
                              <td>{data?.company}</td>

                              <td>
                                {/* {moment(new Date(data?.endDate)).format(
                                  'YYYY/MM/d'
                                )} */}
                                {new Date(data?.endDate).toLocaleDateString()}
                              </td>
                              {/* <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              color=""
                              size="sm"
                              className="btn-icon-only text-light"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td> */}
                              <td>
                                <UncontrolledDropdown className="text-dark" nav>
                                  <DropdownToggle
                                    className="nav-link pr-0"
                                    color=""
                                    tag="a"
                                  >
                                    <div className="p-1 pl-2 pr-2  rounded   shadow c-pointer ">
                                      <i className="fas fa-ellipsis-h"></i>
                                    </div>
                                  </DropdownToggle>
                                  <DropdownMenu left>
                                    <DropdownItem
                                      onClick={() => {
                                        setProjet(data);
                                        setModalEditShow(true);
                                      }}
                                    >
                                      {
                                        languageState.translation.COMMON.BUTTONS
                                          .EDIT
                                      }
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        deleteMissionFN(data.id);
                                      }}
                                    >
                                      {
                                        languageState.translation.COMMON.BUTTONS
                                          .DELETE
                                      }
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        setProjectId(data.id);
                                        setShowConfirmation(true);
                                      }}
                                    >
                                      {
                                        languageState.translation.COMMON.BUTTONS
                                          .ARCHIVE
                                      }
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card>
              </div>
            </Row>
          </Col>
          {/* <Col xl="5" className="h-100">
            <CardCalendar />
          </Col> */}
          <Col xl="12">
            <CardNotifications />
          </Col>
          {/* <Col xl="5">
            <Carousel feeds={homeState?.feeds ? homeState.feeds : []} />{' '}
          </Col> */}
        </Row>
        <ModalAddProject
          modalShow={modalShow}
          closeModal={() => setModalShow(false)}
        />
        <ModalMainInfo
          modalShow={modalInfoShow}
          closeModal={() => setModalInfoShow(false)}
          mainInfo={mainInfo}
          header={languageState.translation.FOCUS.DASHBOARD.PROJECT_DESCRIPTION}
        />
        <ModalEditprojet
          modalShow={modalEditShow}
          closeModal={() => setModalEditShow(false)}
          projet={projet}
        />
        <ConfirmationModal
          headerTitle={
            languageState.translation.HOME.DASHBOARD.ARCHIVE_CONFIRMATION
          }
          modalShow={showConfirmation}
          closeModal={() => {
            setShowConfirmation(false);
          }}
          submitHandler={archive}
        />
      </Container>
    </>
  );
};

export default Dashboard;
