import useHttpClient from 'services/http-client-hook';

const useFilesService = () => {
  const { httpClient } = useHttpClient();

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    return httpClient.post('/upload', formData);
  };

  const uploadMultipleFiles = async (files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('images', file);
    });
     return httpClient.post('/upload/multiple', formData);

  };

  return {
    uploadFile,
    uploadMultipleFiles,
  };
};

export default useFilesService;
