import TYPES from './connect.types';

const reducer = (state, action) => {
  switch (action.type) {
    case TYPES.GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.allUsers,
        config: action.config,
      };
    case TYPES.GET_CONNECTED_USERS:
      return {
        ...state,
        connectedUsers: action.connectedUsers,
        connectedUsersConfig: action.config,
        config: action.config,
      };
    case TYPES.SELECT_USER:
      return {
        ...state,
        activeUser: action.activeUser,
      };
    case TYPES.GET_ALL_NOTES:
      return {
        ...state,
      };
    case TYPES.GET_ALL_GROUPS:
      return {
        ...state,
        groups: action.groups,
        configPublicGroup: action.configPublicGroup
          ? action.configPublicGroup
          : null,
      };
    case TYPES.GET_ALL_GROUP_USERS:
      return {
        ...state,
        groupUsers: action.groupUsers,
      };
    case TYPES.GET_ALL_FAVORITES:
      return {
        ...state,
        favorites: action.favorites,
        configFavorites: action.configFavorites,
      };
    case TYPES.GET_ALL_ARCHIVE:
      return {
        ...state,
        groupArchive: action.archive,
      };

    case TYPES.GET_TAGGED_USERS: {
      return {
        ...state,
        taggedUsers: action.taggedUsers,
      };
    }
    case TYPES.GET_ALL_TAGS: {
      return {
        ...state,
        tags: action.tags,
        tagsTitles: action.tags,
      };
    }

    case TYPES.GET_USER_TAGS: {
      return {
        ...state,
        userTags: action.userTags,
      };
    }

    case TYPES.GET_USERS_COUNTRIES: {
      return {
        ...state,
        countries: action.countries,
      };
    }
    case TYPES.GET_ASSIGNED_SKILLS: {
      return {
        ...state,
        assignedSkills: action.assignedSkills,
      };
    }

    default:
      return {
        ...state,
        listeOfNote: action.listeOfNote,
      };
  }
};

export default reducer;
