import TYPES from './potential-revenue.types';
import reducer from './potential-revenue.reducer';

const INITIAL_STATE = {
    jobApplications: []
};

const POTENTIAL_REVENUE_STATE = {
    types: TYPES,
    initialState: INITIAL_STATE,
    reducer,
};

export default POTENTIAL_REVENUE_STATE;
