import React, { useContext } from 'react';
import { Modal, ModalHeader, ModalBody, Input, Label } from 'reactstrap';
import { AppContext } from 'store/app.context';
import { Formik } from 'formik';
import schema from './Validators';
import { toast } from 'react-toastify';
import './leadsStyles.css';
import Select from 'react-select';
import { useLeadsService } from 'services/leads/leads.service';
import LEADS_STATE from 'store/leads/leads.state';

export const ModalAddLead = ({ modalShow, closeModal }) => {
  const { languageState,leadsState, dispatchLeadsState } = useContext(AppContext);
  const { addLead } = useLeadsService();

  const dealStageOptions = [
    { value: "APPLIED", label: languageState.translation.COMMON.STATUS_LIST.APPLIED },
    { value: "INTRODUCED", label: languageState.translation.COMMON.STATUS_LIST.INTRODUCED },
    { value: "CONNECTED", label: languageState.translation.COMMON.STATUS_LIST.CONNECTED },
    { value: "INTERVIEW_PLANNED", label: languageState.translation.COMMON.STATUS_LIST.INTERVIEW_PLANNED },
    { value: "SELECTED", label: languageState.translation.COMMON.STATUS_LIST.WON },
    { value: "REJECTED", label: languageState.translation.COMMON.STATUS_LIST.LOST }
  ]

  const sourceOptions = [
    { value: 'LINKEDIN', label: 'LinkedIn' },
    { value: 'FACEBOOK', label: 'Facebook' },
    { value: 'WEBSITE', label: 'Website' },
    { value: 'PERSONAL_NETWORK', label: 'Personal Network' },
    { value: 'OTHER', label: 'Other' },
  ]
  const addLeadHandler = async (values) => {
    if (values) {
      const result = await addLead(values);
      dispatchLeadsState({
        type: LEADS_STATE.types.ADD_LEAD, 
        lead: values,
      });
      toast.success(languageState.translation.FOCUS.DASHBOARD.LEAD_ADDED, {
        position: 'bottom-right',
        hideProgressBar: false,
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      closeModal();
    }
  };
  
  return (
    <Modal
      isOpen={modalShow}
      toggle={closeModal}
      style={{
        maxWidth: '25rem',
        height: '100vh',
        margin: 0,
        marginLeft: 'auto',
        marginRight: '0',
        overflowY:'hidden'
      }}
    >
      <ModalHeader
        close={
          <button className="close" onClick={closeModal}>
            <i className="fa fa-times" />
          </button>
        }
        className="bg-danger d-flex align-items-center mb-1 p-2"
      >
        <p className='text-white font-weight-bold'>
        {languageState.translation.COMMON.ADD_LEAD}
        </p>
      </ModalHeader>
      <ModalBody
        className="m-0 pt-0 p-2"
        style={{
          height: '100vh', 
        }}
      >
        <Formik
          initialValues={{
            title: '',
            company: '',
            description: '',
            alias: '',
            estimatedRevenue: '',
            stage: '',
            source: '',
            link: '',
          }}
          validationSchema={schema(languageState.translation.FORM_VALIDATION)}
          onSubmit={async (values) => {
              await addLeadHandler(values);  
            }  }       
        >
          {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
            <div className="h-100 d-flex flex-column justify-content-between">
              <div className="d-flex flex-column">
                {/* Use flex-column for a one-column layout */}
                <Label className="">{languageState.translation.COMMON.LEAD_TITLE}</Label>
                <Input name="title" onChange={handleChange} className=''/>
                <p className="text-red">{errors.title && touched.title && errors.title}</p>

                <Label className="">{languageState.translation.COMMON.COMPANY} *</Label>
                <Input name="company" onChange={handleChange} />
                <p className="text-red">{errors.company && touched.company && errors.company}</p>

                <Label className="">{languageState.translation.JOBBOARD.ASSIGN_ALIAS}</Label>
                <Input name="alias" onChange={handleChange} />

                <Label className="">{languageState.translation.JOBBOARD.POTENTIAL_REVENUE} (€) *</Label>
                <Input type="number" name="estimatedRevenue" onChange={handleChange} />
                <p className="text-red">{errors.estimatedRevenue && touched.estimatedRevenue && errors.estimatedRevenue}</p>

                <Label className="">{languageState.translation.COMMON.DEAL_STAGE} *</Label>
                <Select
                  className="basic-multi-select"
                  id="stage"
                  name="stage"
                  onChange={(e) => setFieldValue('stage', e.value)}
                  options={dealStageOptions}
                />
                        <p className="text-red">{errors.stage && touched.stage && errors.stage}</p>


                <Label className="">{languageState.translation.COMMON.SOURCE} *</Label>
                <Select
                  className="basic-multi-select"
                  classNamePrefix="select"
                  id="source"
                  name="source"
                  onChange={(e) => setFieldValue('source', e.value)}
                  options={sourceOptions}
                />
                <p className="text-red">{errors.source && touched.source && errors.source}</p>


                <Label className="">{languageState.translation.COMMON.LINKS}</Label>
<Input type="url" name="link" onChange={handleChange} />
<p className="text-red">{errors.link && errors.link}</p>
                <Label className="">{languageState.translation.COMMON.DESCRIPTION}</Label>
                <Input type="textarea" name="description" rows="12" onChange={handleChange} />
              </div>
              <div className="text-right m-1 d-flex justify-content-between align-items-center">
                <p
                  className="font-weight-bold cursor-pointer"
                  onClick={closeModal} 
                >
                  {languageState.translation.COMMON.BUTTONS.CANCEL}
                </p>

                <button
                  className="btn bg-danger text-white"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                >
                  {languageState.translation.COMMON.BUTTONS.SUBMIT}
                </button>
              </div>
            </div>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};