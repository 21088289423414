import TYPES from './leads.types';

const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.SET_LEADS:
            return{
                ...state,
                leads: action.leads
            }
            case TYPES.UPDATE_LEAD:
                return {
                    ...state,
                    leads: state.leads.map((lead) =>
                        lead.id === action.lead.id
                          ? { ...lead, stage: action.lead.stage }
                          : lead
                      ),
                    };
            case TYPES.ADD_LEAD:
                return{
                    ...state,
                    leads: [...state.leads, action.lead]
                }    
        default:
            return state;
    }

};

export default reducer;
