import { API_URL_CHAT } from 'config/core';
import React, { createContext, useEffect, useRef } from 'react';
import { useContext } from 'react';
import io from 'socket.io-client';
import { AppContext } from 'store/app.context';
import { getChatToken } from 'utils';

const WebSocketContext = createContext(null);
export { WebSocketContext };

const SocketProvider = ({ children }) => {
  const socketRef = useRef(null);
  const { userState } = useContext(AppContext);

  /*useEffect(() => {
    const token = userState?.chatToken;
    if (!socketRef.current && token) {
     socketRef.current = io(API_URL_CHAT, {
        path: '/realtime',
        secure: process.env.NODE_ENV === 'production',
        query: {
          accessToken: token,
        },
        transports: ['websocket', 'polling'],
      });

      socketRef.current.on('Notifications', (data) => {
        console.log('NOTIFS');
        console.log(data);
      });

      return () => {
        socketRef.current.off('Notifications');
      };
    }
  }, [userState?.chatToken]);*/

  return (
    <WebSocketContext.Provider value={socketRef.current}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default SocketProvider;
