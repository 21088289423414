
import useFilesService from "services/files/files.services";
import useHttpClient from "services/http-client-hook";

const useFeedbackService = () => {
    const { httpClient } = useHttpClient();
    const { uploadMultipleFiles } = useFilesService();

    const addFeedback = async (data) => {

        const payload ={}
        payload.message =  data.message ;
        const result = await uploadMultipleFiles(data.screenshots)
        if(result && result.status == 201)
        {
          const fileNames = result.data.map((item) => item.filename)
          payload.attachments=  fileNames
        }
        else
        {
          console.error("error uploading files")
        }

        const response =  httpClient.post('/feedback', payload);
      return response;
    }

    return {
        addFeedback
    }
}

export default useFeedbackService