import reducer from './connect.reducer';
import TYPES from './connect.types';

const INITIAL_STATE = {
  allUsers: null,
  connectedUsers: null,
  activeUser: null,
  config: null,
  connectedUsersConfig: null,
  listeOfNote: null,
  groups: null,
  favorites: null,
  groupUsers: null,
  configFavorites: null,
  groupArchive: null,
  configPublicGroup: null,
  tags: null,
  userTags: null,
  tagsTitles: null,
  taggedUsers: null,
  countries: null,
  assignedSkills:null
};

const CONNECT_STATE = {
  types: TYPES,
  initialState: INITIAL_STATE,
  reducer,
};

export default CONNECT_STATE;
