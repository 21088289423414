import { useContext } from 'react';
import { AppContext } from 'store/app.context';
import EDUCATIONS_STATE from 'store/settings/educations/educations.state';
import EXPERIENCES_STATE from 'store/settings/experiences/experiences.state';
import LANGUAGES_SETTINGS_STATE from 'store/settings/languages/languages.state';
import POSITIONS_STATE from 'store/settings/positions/positions.state';
import SKILLS_STATE from 'store/settings/skills/skills.state';
import useHttpClient from '../http-client-hook';

const useSettingsService = () => {
  const { httpClient } = useHttpClient();
  const {
    dispatchExperienceState,
    dispatchEducationState,
    dispatchSkillsState,
    dispatchLanguageSettingsState,
    dispatchPositionState,
  } = useContext(AppContext);

  const getExperiences = async () => {
    const result = await httpClient.get('/experience/mine');
    if (result && result.status == 200) {
      dispatchExperienceState({
        type: EXPERIENCES_STATE.types.SET_EXPERIENCES,
        experiences: result.data,
      });
    }
  };

  const editExperience = async (payload) =>
    httpClient.patch(`/experience/${payload.id}`, payload);
  const addExperience = async (payload) =>
    httpClient.post('/experience', payload);

  const deleteExperience = async (payload) =>
    httpClient.delete(`/experience/${payload.id}`);

  const getEducations = async () => {
    const result = await httpClient.get('/education/mine');
    if (result && result.status == 200) {
      dispatchEducationState({
        type: EDUCATIONS_STATE.types.SET_EDUCATIONS,
        educations: result.data,
      });
    }
  };

  const addEducation = async (payload) =>
    httpClient.post('/education', payload);

  const deleteEducation = async (payload) =>
    httpClient.delete(`/education/${payload.id}`);

  const getSkills = async () => {
    const result = await httpClient.get('/skills');
    if (result && result.status == 200) {
      dispatchSkillsState({
        type: SKILLS_STATE.types.SET_SKILLS,
        skills: result.data,
      });
    }
  };

  const getMySkills = async () => {
    const result = await httpClient.get('/skills/mine');
    if (result && result.status == 200) {
      dispatchSkillsState({
        type: SKILLS_STATE.types.SET_SKILLS_MINE,
        mine: result.data,
        skills:[]
      });
    }
  };

  const addSkill = async (payload) => httpClient.post('/skills', payload);

  const deleteSkill = async (skillId) =>
    httpClient.delete(`/skills/${skillId}`);

  const getLanguages = async () => {
    const result = await httpClient.get('/languages/mine');
    if (result && result.status == 200) {
      dispatchLanguageSettingsState({
        type: LANGUAGES_SETTINGS_STATE.types.SET_LANGUAGES,
        languages: result.data,
      });
    }
  };

  const deleteLanguage = async (id) => httpClient.delete(`/languages/${id}`);

  const addLanguage = async (payload) => httpClient.post('/languages', payload);

  const getPositions = async () => {
    const result = await httpClient.get('/positions');
    if (result && result.status == 200) {
      dispatchPositionState({
        type: POSITIONS_STATE.types.SET_POSITIONS,
        positions: result.data,
      });
    }
  };

  const addPortfolio = async (payload) =>
    httpClient.post('/portfolio', payload);

  const editPortfolio = async (payload, portfolioId) =>
    httpClient.patch(`/portfolio/${portfolioId}`, payload);

  const getPortfolio = async (userId) =>
    httpClient.get(`/portfolio/byUser/${userId}`);

  const addAvailibility = async (payload) =>
    httpClient.post(`/availability`, payload);

  const getMyAvailibility = async () => httpClient.get(`/availability/mine`);

  const deleteAvalibility = async (id) =>
    httpClient.delete(`/availability/${id}`);

  const addHoliday = async (payload) => httpClient.post('/holidays', payload);

  const deleteHoliday = async (id) => httpClient.delete(`/holidays/${id}`);

  const getHolidays = async () => httpClient.get(`/holidays/mine`);

  const deleteAccount = async () => httpClient.delete(`/users/me`);

  /* */
  const getTimeZones = async () => {
    const result = await httpClient.get('/time-zones', {});
    if (result && result.status === 200) {
      return result.data;
    }
  }

  return {
    getExperiences,
    addExperience,
    editExperience,
    deleteExperience,
    getEducations,
    addEducation,
    deleteEducation,
    getSkills,
    getMySkills,
    addSkill,
    deleteSkill,
    getLanguages,
    addLanguage,
    deleteLanguage,
    getPositions,
    addPortfolio,
    getPortfolio,
    editPortfolio,
    addAvailibility,
    getMyAvailibility,
    deleteAvalibility,
    getHolidays,
    addHoliday,
    deleteHoliday,
    deleteAccount,
    getTimeZones
  };
};

export default useSettingsService;
