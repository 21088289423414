import { useContext } from 'react';
import { AppContext } from 'store/app.context';
import * as Yup from 'yup';

const schema = (validationMessages) => {
    const {languageState} = useContext(AppContext)
    return Yup.object().shape({
        company: Yup.string().required(validationMessages.companyRequired || languageState.translation.COMMON.ERRORS.COMPANY_REQUIRED),
        estimatedRevenue: Yup.number().required(validationMessages.potentialRevenueRequired || languageState.translation.COMMON.ERRORS.REVENUE_REQUIRED),
        stage: Yup.string().required(validationMessages.dealStageRequired || languageState.translation.COMMON.ERRORS.STAGE_REQUIRED),
        source: Yup.string().required(validationMessages.sourceRequired || languageState.translation.COMMON.ERRORS.SOURCE_REQUIRED),
        link: Yup.string().url(validationMessages.invalidLink || languageState.translation.COMMON.ERRORS.LINK_URL).nullable(),
    });
};

export default schema;
