import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import './index.scss'
import { Link } from "react-router-dom";
import APP_ROUTES from "navigation/app.routes";

const BreadcrumbComponent = ({ breadCrumbActive, breadCrumbLinks = [], homeJobs, jobDetail }) => {
  

    return (
        <Breadcrumb>
            <BreadcrumbItem tag='li' >
                <Link to={APP_ROUTES.CONNECT.ALL_NETWORK}>
                    <i className="fa fa-home" /> {homeJobs && ` - ${homeJobs}`}
                </Link>
            </BreadcrumbItem>
            {jobDetail && (
                <BreadcrumbItem tag='li' >
                    <Link to={jobDetail.link} className='c-pointer'>{jobDetail.label}</Link>
                </BreadcrumbItem>
            )}
            {breadCrumbLinks && (
                breadCrumbLinks.map(({ label, link, type }, index) => (
                    <BreadcrumbItem key={index}>
                        {
                            type === 'return_to_previous' ? (
                                <Link className="c-pointer" to={link}>{label}</Link>
                            ) : (
                                <Link className='text-white c-pointer' to={link}>{label}</Link>
                            )
                        }
                    </BreadcrumbItem>
                ))
            )}
           
            {breadCrumbActive && (
                <BreadcrumbItem tag='li' active>
                    {breadCrumbActive}
                </BreadcrumbItem>
            )}
        </Breadcrumb>
    )
}

export default BreadcrumbComponent;