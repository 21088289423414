import TYPES from './planned-revenue.types';

const reducer = (state, action) => {
    switch (action.type) {
       
            case TYPES.EDIT_JOB_APPLICATION:
                const jobApplications = state.jobApplications?.length? state.jobApplications.filter((e) => 
                    e.id !== action.jobApplication.id 
                ) : state.jobApplications
                const newJobApplications = [
                    ...jobApplications,
                    action.jobApplication
                ]
                return {
                    ...state,
                    jobApplications: newJobApplications
                }
        default:
            return state;
    }
};

export default reducer;
