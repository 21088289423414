import CardsHeaderFocus from 'components/cards/card-header-focus';
import React, { useContext, useEffect, useState } from 'react';
import { Badge, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { AppContext } from 'store/app.context';
import MicroJobCard from './components/MicroJobCard';
import MicroJobCard2 from './components/MicroJobCard2';
import MicroJobCard3 from './components/MicroJobCard3';
import MicroJobCard4 from './components/MicroJobCard4';
import { CountryDropdown } from 'react-country-region-selector';
import JobsContainer from '../JobsContainer';
const MicroJobs = () => {
  const { languageState } = useContext(AppContext);
  const [countries, setCountyList] = useState(['Belguim', 'Tunisia', 'France']);

  return (
    <JobsContainer>
      <div>
        <p></p>
        <CardsHeaderFocus
          name={'Zoekvragen van freelancers en bedrijven'}
          parentName="Dashboards"
          subName="10 nieuwe berichten"
        />
        <div className="container-fluid mt-4">
          <div>
            <Row>
              <Col className="col-lg-3 col-sm-12 col-12 d-none">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">
                      {languageState.translation.COMMON.COUNTRY}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <div className="custom-control custom-checkbox mb-3">
                        {countries?.map((// @ts-ignore
                          country
                        ) => (
                          <Col md="12" key={country}>
                            <input
                              className="custom-control-input"
                              id={country}
                              type="checkbox"
                              //   checked={filters.country.includes(country)}
                              onChange={(e) => { }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={country}
                            >
                              {country}
                            </label>
                          </Col>
                        )
                        )}
                      </div>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h3 className="mb-0">
                      {languageState.translation.COMMON.TYPE}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            id="customCheck1"
                            type="checkbox"
                            onChange={(e) => { }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck1"
                          >
                            {languageState.translation.COMMON.FULL_TIME}
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            id="customCheck2"
                            type="checkbox"
                            onChange={(e) => { }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            {languageState.translation.COMMON.PART_TIME}
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h3 className="mb-0">
                      {languageState.translation.COMMON.CATEGORY}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {' '}
                      <Col md="12">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                          />
                          <label className="custom-control-label">
                            Copywriter
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                          />
                          <label className="custom-control-label">
                            Virtual Assistant
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="custom-control custom-checkbox mb-3">
                          s
                          <input
                            className="custom-control-input"
                            type="checkbox"
                          />
                          <label className="custom-control-label">
                            UX-Designer
                          </label>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="custom-control custom-checkbox mb-3">
                          <input
                            className="custom-control-input"
                            type="checkbox"
                          />
                          <label className="custom-control-label">
                            Content Editor
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>{' '}
              <Col>
                <Row>
                  <Col lg="12">
                    <MicroJobCard />
                  </Col>
                  <Col lg="12">
                    <MicroJobCard2 />
                  </Col>
                  <Col lg="12">
                    <MicroJobCard3 />
                  </Col>
                  <Col lg="12">
                    <MicroJobCard4 />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </JobsContainer>
  );
};

export default MicroJobs;
