import React, { useContext, useState } from 'react';
import { useDrag } from 'react-dnd';
import { Button, Card } from 'reactstrap';
import moment from 'moment';
import { AppContext } from 'store/app.context';
import "./sales-pipeline.css"
import { useHistory } from 'react-router-dom';

const ApplicationCard = ({ application }) => {
  const history = useHistory()

  const { languageState } = useContext(AppContext);
  //const [modalShow, setModalShow] = useState(false);

  const [{ isDragging }, drag] = useDrag({
    type: 'TASK',
    item: { id: application.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('nl-BE', {
        style: 'currency',
        currency: 'EUR'
    }).format(amount);
};
  return (
    <>
    <Card className={`task-card ${isDragging ? 'dragging' : ''} d-flex align-items-start flex-column p-1 bg-secondary`}>
    <div className='d-flex justify-content-between w-100'>
      <h4 className='functionTitle ml-1'>{application.alias ? application.alias : application.job.functionTitle}</h4>
      <i className="fas fa-briefcase"></i>    </div>
      <div className='details'>
        <p className="details-property ml-1">{languageState.translation.COMMON.APPLICATION_DATE}: {moment(application.createdAt).format('DD-MM-YYYY')} </p>
        <p className="details-property ml-1">{languageState.translation.JOBBOARD.POTENTIAL_REVENUE}: {application.estimatedRevenue ? `${formatCurrency(application.estimatedRevenue)}` : `${formatCurrency(0)}`} </p>

        <p className="details-property ml-1">{languageState.translation.JOBBOARD.VIEWS} : {application.job.nbViews}</p>
        <p className="details-property ml-1">{application.job.applicantCount ? `${languageState.translation.COMMON.OTHER_CANDIDATES} :  ${application.job.applicantCount}` : '' }</p>
      </div>
      <Button
                color="danger"
                outline
                className="w-100 mt-2 shadow-none"
                onClick={() => {
                  sessionStorage.setItem("previousUrl", "pendingApplications");
                  history.push(`/private/jobs/job-details/${application.job.id}`)
                  }}>
                {languageState.translation.COMMON.SHOW_DETAILS}
              </Button>
        
    </Card>
  
     {/* 
      <ChooseActionModal modal={modalShow} closeModal={() => setModalShow(false)} jobId={application.job.id} title={application.job.functionTitle} application={application} className="modal" />
     */}
       
        </>
  )



};

export default ApplicationCard;
