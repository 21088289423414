import { LEADS } from "config/mock/leads/leads";
import { useContext } from "react";
import useHttpClient from "services/http-client-hook";
import { AppContext } from "store/app.context";
import LEADS_STATE from "store/leads/leads.state";

export const useLeadsService = () => {
    const { dispatchLeadsState } = useContext(AppContext);
    const { httpClient } = useHttpClient();

    const getLeads = async () => {
        try {
        const response = await httpClient.get('/leads');
        dispatchLeadsState({
            type: LEADS_STATE.types.SET_LEADS,
            leads: response.data,
        });
        } catch (error) {
        console.error("Error fetching leads:", error);
      }

    }
    const addLead = async (lead) => {
        const result = await httpClient.post('/leads', lead)
        
    }
    const getLeadDetail = async (id) => {
        const response = await httpClient.get(`/leads/${id}`);
        return response.data;
    };
    const addNotes= async(id, payload) => {
        return await httpClient.post(`/leads/${id}/notes`, payload);
    }

    const updateLead = async (updatedLead, id) => {
        return await httpClient.patch(`/leads/${id}`, updatedLead);
    }

    const deleteLead = async(id) => {
        return await httpClient.delete(`/leads/${id}`);
    }

    return {
        getLeads,
        addLead,
        getLeadDetail,
        updateLead,
        addNotes, 
        deleteLead
    }

}
