const { default: TYPES } = require('./jobs.types');

const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.SET_JOBS:
            return {
                ...state,
                jobs: action.jobs,
                config: action.config,
                availableSkills:action.availableSkills
            };
        case TYPES.SET_JOBS_SAVED:
            return {
                ...state,
                jobSaved: action.jobSaved,
            };
        case TYPES.SET_JOB:
            return {
                ...state,
                job: action.job,
            };
        case TYPES.GET_CATEGORIES:
            return {
                ...state,
                categories: action.categories,
            };
        default:
            return state;

    }
};
export default reducer;
