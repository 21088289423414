import React, { useContext } from 'react';
import { useDrag } from 'react-dnd';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'store/app.context';
import "./sales-pipeline.css";

const LeadCard = ({ lead }) => {
  const history = useHistory();
  const { languageState } = useContext(AppContext);

  const [{ isDragging }, drag] = useDrag({
    type: 'LEAD',
    item: { id: lead.id, currentStatus: lead.status },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('nl-BE', {
        style: 'currency',
        currency: 'EUR'
    }).format(amount);
};
  return (
    <div
      ref={drag}
      className={`task-card ${isDragging ? 'dragging' : ''} d-flex align-items-start flex-column p-1 cursor-pointer`}
    >
          <div className='d-flex justify-content-between w-100 text-center'>

      <h4 className='functionTitle ml-1 text-center'>{lead.alias ? lead.alias : (lead?.title ? lead?.title :"No title" )}</h4>
      <i className="fas fa-grip-lines"></i>
      </div>
      <div className="details">
        <p className="details-property ml-1">
          {languageState.translation.COMMON.COMPANY}: {lead.company}
        </p>
        <p className="details-property ml-1">
          {languageState.translation.JOBBOARD.POTENTIAL_REVENUE}:{lead?.estimatedRevenue ? `${formatCurrency(lead?.estimatedRevenue)}` : `${formatCurrency(0)}`}
        </p>
      </div>
      <Button
        color="danger"
        outline
        className="w-100 mt-2 shadow-none"
        onClick={() => {
          sessionStorage.setItem("previousUrl", "leads");
          history.push(`/private/leads/lead-details/${lead.id}`);
        }}
      >
        {languageState.translation.COMMON.SHOW_DETAILS}
      </Button>
    </div>
  );
};

export default LeadCard;
