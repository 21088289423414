import TYPES from './applications.types';
import reducer from './applications.reducer';

const INITIAL_STATE = {
    applications: []
};

const APPLICATIONS_STATE = {
    types: TYPES,
    initialState: INITIAL_STATE,
    reducer,
};

export default APPLICATIONS_STATE;
