import TYPES from './planned-revenue.types';
import reducer from './planned-revenue.reducer';

const INITIAL_STATE = {
    jobApplications : []
};

const PLANNED_REVENUE_STATE = {
    types: TYPES,
    initialState: INITIAL_STATE,
    reducer,
};

export default PLANNED_REVENUE_STATE;