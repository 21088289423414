import React, { useContext, useState } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// reactstrap components
import { Button, Container } from 'reactstrap';
import { AppContext } from 'store/app.context';
import ModalAddUser from 'components/ModalAddUser/ModalAddUser';
import BreadcrumbComponent from 'components/breadcrumb';
import APP_ROUTES from 'navigation/app.routes';

const CardsHeaderFocus = ({
  name,
  parentName,
  type,
  subName,
  breadcrumb,
  className,
  published,
  breadCrumbLinks,
}) => {
  const { languageState } = useContext(AppContext);
  const [showModal, setModalShow] = useState(false);

  return (
    <>
      <div className="header bg-exprimary text-white pb-4">
        <Container fluid className="d-flex flex-column align-items-start">
          {breadcrumb && (
            <div className={`breadcrumb-wrapper ${className} w-100`}>
              <BreadcrumbComponent
                jobDetail={{
                  label: languageState.translation.SIDEBAR.JOBS,
                  link: `/private${APP_ROUTES.JOBS.BOARD}`,
                }}
                breadCrumbActive={name}
                homeJobs={languageState.translation.COMMON.JOBS.BOARD}
                breadCrumbLinks={breadCrumbLinks}
              />
            </div>
          )}

          <div className="d-flex w-100 align-items-center">
            <div>
              <h1 className="display-4 font-weight-bold mb-2 text-white">{name}</h1>
              {published && (
                <p className="mb-0 font-weight-bold">
                  {languageState.translation.COMMON.PUBLISHED}: {published}
                </p>
              )}
            </div>
            {parentName === 'Teams' && type === 'PROJET' && (
              <Button
                className="btn btn-light rounded-pill px-4 py-2"
                onClick={() => setModalShow(true)}
              >
                {languageState.translation.FOCUS.TEAMS.ADD_TEAM}
              </Button>
            )}
          </div>

          {subName && <p className="mt-2 text-muted">{subName}</p>}
        </Container>

        <ModalAddUser modalShow={showModal} closeModal={() => setModalShow(false)} />
      </div>
    </>
  );
};

CardsHeaderFocus.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeaderFocus;
