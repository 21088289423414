import { useContext } from 'react';
import { AppContext } from 'store/app.context';
import SUBSCRIPTIONS_STATE from 'store/subscriptions';
import useHttpClient from '../http-client-hook';

const useSubscriptionSerivce = () => {
    const { httpClient } = useHttpClient();
    const { dispatchSubscriptionState } = useContext(AppContext);

    const getSubscriptions = async () => {
        const result = await httpClient.get('/subscription');
        if (result && result.status == 200) {
            dispatchSubscriptionState(
                {
                    type: SUBSCRIPTIONS_STATE.types.SET_SUBSCRIPTIONS,
                    subscriptions: result.data,

                })
        }
    }
    return {
        getSubscriptions
    }
}
export default useSubscriptionSerivce;