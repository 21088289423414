import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';

import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Input, Label, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useLeadsService } from 'services/leads/leads.service';
import { AppContext } from 'store/app.context';
import TYPES from 'store/leads/leads.types';
import CardsHeaderFocus from 'components/cards/card-header-focus';
import APP_ROUTES from 'navigation/app.routes';
import moment from 'moment';
import LEADS_STATE from 'store/leads/leads.state';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import APPLICATIONS_STATE from 'store/applications/applications.state';
import { toast } from 'react-toastify';

export const LeadDetails = () => {
    const { languageState, leadsState, dispatchLeadsState } = useContext(AppContext)
    const [leadData, setLeadData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [backupData, setBackupData] = useState(null);
    const [note, setNote] = useState('');
    const [notes, setNotes] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const history = useHistory()


    const [isAddingNote, setIsAddingNote] = useState(false);

    const { getLeadDetail, addNotes, deleteLead, updateLead } = useLeadsService();
    const prevUrl = sessionStorage.getItem("previousUrl");

    const { id } = useParams();

    const dealStageOptions = [
        { value: "APPLIED", label: languageState.translation.COMMON.STATUS_LIST.APPLIED },
        { value: "INTRODUCED", label: languageState.translation.COMMON.STATUS_LIST.INTRODUCED },
        { value: "CONNECTED", label: languageState.translation.COMMON.STATUS_LIST.CONNECTED },
        { value: "INTERVIEW_PLANNED", label: languageState.translation.COMMON.STATUS_LIST.INTERVIEW_PLANNED },
        { value: "SELECTED", label: languageState.translation.COMMON.STATUS_LIST.WON },
        { value: "REJECTED", label: languageState.translation.COMMON.STATUS_LIST.LOST }
    ]

    const sourceOptions = [
        { value: 'LinkedIn', label: 'LinkedIn' },
        { value: 'Facebook', label: 'Facebook' },
        { value: 'Website', label: 'Website' },
        { value: 'Personal Network', label: 'Personal Network' },
        { value: 'Other', label: 'Other' },
    ]

    const getLead = async (id) => {
        const lead = await getLeadDetail(id);

        const getUserNote = async () => {
            if (lead) {
                const notes = lead.notes;
                setNotes(notes);
            }
        };
        getUserNote()
        setLeadData(lead);
        setBackupData(lead);
    };

    useEffect(() => {
        if (id) {
            getLead(id);
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedValue = name === "estimatedRevenue" ? parseFloat(value) : value;

        setLeadData({ ...leadData, [name]: updatedValue });
    };

    const handleSourceChange = (selectedOption) => {
        setLeadData((prevData) => ({
            ...prevData,
            source: selectedOption.value,
        }));
    }
    const handleDealChange = (selectedOption) => {
        setLeadData((prevData) => ({
            ...prevData,
            stage: selectedOption.value,
        }));
    }
    const handleToggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handleSave = async () => {
        await updateLead(leadData, leadData.id);
        dispatchLeadsState({
            type: LEADS_STATE.types.UPDATE_LEAD,
            lead: { ...leadData },
        });

        setBackupData(leadData);
        setIsEditing(false);
    };

    const handleCancel = () => {
        setLeadData(backupData);
        setIsEditing(false);
    };

    const handleNoteContentChange = (content) => {
        setNote(content);
    };
    const handleAddNoteClick = () => {
        setIsAddingNote(true);
    };

    const handleSaveNote = async () => {
        if (note) {
            const payload = { note: note };
            const data = await addNotes(leadData.id, payload);

            const createdAt = new Date()
            const newNote = { note: note, created_at: createdAt };
            notes.unshift(newNote);
            setNote('')
        }
        setIsAddingNote(false);
    };

    const handleDelete = async () => {
        const response = await deleteLead(id);
        toast.success(languageState.translation.FOCUS.DASHBOARD.LEAD_DELETED, {
            position: 'bottom-right',
            hideProgressBar: false,
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
        });
        history.push(`/private/jobs/pending_applications`)
        setIsDeleteModalOpen(false);
    }
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('nl-BE', {
            style: 'currency',
            currency: 'EUR'
        }).format(amount);
    };
console.log(leadData)
    return (
        <>
            <CardsHeaderFocus
                name={leadData?.title}
                breadcrumb
                className='lead-breadcrumb'
                published={moment(leadData?.createdAt).format('DD MMMM YYYY')} type={undefined} subName={undefined}
                breadCrumbLinks={[{ label: `${languageState.translation.SIDEBAR.PENDING_APPLICATIONS}`, link: `/private${APP_ROUTES.JOBS.PENDING_APPLICATIONS}` }]}
            />
            <Container fluid className="lead-details mt-3">
                <Row>
                    <Col lg="7" md="12">
                        <Card>
                            <CardBody className="p-4">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h1 className="font-weight-bold">{backupData?.alias ? backupData?.alias : (backupData?.title ? backupData?.title : "No title")}</h1>
                                    <div>
                                        {isEditing && (
                                            <div>
                                                <Button color="primary" onClick={handleSave} className="ml-2">
                                                    <i className="fas fa-save mr-2"></i>{languageState.translation.COMMON.BUTTONS.SAVE}
                                                </Button>
                                                <Button color="danger" onClick={handleCancel} className="mr-2">
                                                    <i className="fas fa-times mr-2"></i>
                                                    {languageState.translation.COMMON.BUTTONS.CANCEL}
                                                </Button>
                                            </div>
                                        )}
                                        {!isEditing && (
                                            <div>

                                                <Button color="danger" onClick={() => setIsDeleteModalOpen(true)} className="ml-2">
                                                    <i className="fas fa-trash mr-2"></i>{languageState.translation.COMMON.BUTTONS.DELETE}
                                                </Button>
                                                <Button color="primary" onClick={handleToggleEdit} className="mr-2">
                                                    <i className="fas fa-edit mr-2"></i>
                                                    {isEditing ? languageState.translation.COMMON.BUTTONS.CANCEL : languageState.translation.COMMON.BUTTONS.EDIT}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="lead-detail-field">
                                    <Label className="font-weight-bold">{languageState.translation.COMMON.LEAD_TITLE}</Label>
                                    {isEditing ? (
                                        <Input name="title"
                                            value={leadData?.title}
                                            onChange={handleChange} />
                                    ) : (
                                        <p>{leadData?.title}</p>
                                    )}
                                </div>

                     

                                <div className="lead-detail-field">
                                    <Label className="font-weight-bold">{languageState.translation.COMMON.COMPANY}</Label>
                                    {isEditing ? (
                                        <Input name="company" value={leadData?.company} onChange={handleChange} />
                                    ) : (
                                        <p>{leadData?.company}</p>
                                    )}
                                </div>

                                <div className="lead-detail-field">
                                    <Label className="font-weight-bold">{languageState.translation.COMMON.SHORTCUTNAME}</Label>
                                    {isEditing ? (
                                        <Input name="alias" value={leadData?.alias} onChange={handleChange} />
                                    ) : (
                                        leadData?.alias ? <p>{leadData?.alias}</p> : <p>{languageState.translation.ALERT.NO_ALIAS_AVAILABLE}</p>
                                    )}
                                </div>

                                <div className="lead-detail-field">
                                    <Label className="font-weight-bold">{languageState.translation.JOBBOARD.POTENTIAL_REVENUE} (€)</Label>
                                    {isEditing ? (
                                        <Input type="number" name="estimatedRevenue" value={leadData?.estimatedRevenue} onChange={handleChange} />
                                    ) : (
                                        <p>{leadData?.estimatedRevenue ? `${formatCurrency(leadData?.estimatedRevenue)}` : `${formatCurrency(0)}`}</p>
                                    )}
                                </div>

                                <div className="lead-detail-field">
                                    <Label className="font-weight-bold">{languageState.translation.COMMON.DEAL_STAGE}</Label>
                                    {isEditing ? (
                                        <Select
                                            name="stage"
                                            value={dealStageOptions.find(option => option.value === leadData?.stage)}
                                            onChange={handleDealChange}
                                            options={dealStageOptions}
                                        />
                                    ) : (
                                        <p>{leadData?.stage === 'REJECTED' ? 'Lost' : leadData?.stage === 'SELECTED' ? 'Won' : leadData?.stage}</p>
                                    )}
                                </div>
                                <div className="lead-detail-field">
                                    <Label className="font-weight-bold">{languageState.translation.COMMON.SOURCE}</Label>
                                    {isEditing ? (
                                        <Select
                                            name="source"
                                            value={sourceOptions.find(option => option.value === leadData?.source)}
                                            onChange={handleSourceChange}
                                            options={sourceOptions} />
                                    ) : (
                                        <p>{leadData?.source}</p>
                                    )}
                                </div>

                                <div className="lead-detail-field">
                                    <Label className="font-weight-bold d-block">{languageState.translation.COMMON.LINK}</Label>
                                    {isEditing ? (
                                        <Input type="url" name="link" value={leadData?.link} onChange={handleChange} />
                                    ) : (
                                        leadData?.link ? <a href={leadData?.link} target="_blank" rel="noopener noreferrer" className="text-primary">
                                            {leadData?.link}
                                        </a> : <p>{languageState.translation.ALERT.NO_LINK_AVAILABLE}</p>
                                    )}
                                </div>

                                <div className="lead-detail-field">
                                    <Label className="font-weight-bold">{languageState.translation.COMMON.DESCRIPTION}</Label>
                                    {isEditing ? (
                                        <Input type="textarea" rows="12" name="description" value={leadData.description} onChange={handleChange} />
                                    ) : (
                                        leadData?.description ? <p>{leadData?.description}</p> : <p>{languageState.translation.ALERT.NO_DESCRIPTION_AVAILABLE}</p>
                                    )}
                                </div>
                            </CardBody>

                        </Card>
                    </Col>
                    <Col lg="5" md="12">
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-between">
                                    <h1>{languageState.translation.JOBBOARD.HISTORY}</h1>
                                    {
                                        !isAddingNote &&
                                        <Button color="exprimary" onClick={handleAddNoteClick}>{languageState.translation.COMMON.ADD_NOTE}</Button>
                                    }
                                </div>
                                {isAddingNote && (
                                    <div>
                                        <Input
                                            className='mt-2'
                                            type="textarea"
                                            rows="4"
                                            value={note}
                                            onChange={(e) => handleNoteContentChange(e.target.value)}
                                            autoFocus
                                        />
                                        <Button className='w-2 mt-2' color='primary' onClick={handleSaveNote}>{languageState.translation.COMMON.ADD_NOTE}</Button>
                                    </div>
                                )}
                                <div className='notes-card mt-3'>
                                    <div>
                                        <div className=''>
                                            {notes?.length > 0 ? (
                                                notes?.map((note, index) => (
                                                    <div key={index} className="note-item">
                                                        <p className="note-content">{note.note}</p>
                                                        <small>
                                                            <p>{moment(note.created_at).format("DD-MM-YYYY HH:mm")}</p>
                                                        </small>
                                                    </div>
                                                ))
                                            ) : (
                                                <p className="text-muted">{languageState.translation.ALERT.NO_NOTES_AVAILABLE}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={isDeleteModalOpen} toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>
                <ModalBody>Are you sure you want to delete this lead?</ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={handleDelete}>Delete</Button>
                    <Button color="secondary" onClick={() => setIsDeleteModalOpen(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>

    );
};
