import TYPES from './potential-revenue.types';

const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.SET_POTENTIAL_REVENUE:
            const selectedApplication = action.jobApplications?.filter((app) => app.status !== "SELECTED")
            return {
                ...state,
                jobApplications: selectedApplication ,
            };
            case TYPES.EDIT_JOB_APPLICATION:
                const jobApplications = state.jobApplications.filter((e) => 
                    e.id !== action.jobApplication.id 
                )
                const newJobApplications = [
                    ...jobApplications,
                    action.jobApplication
                ]
                console.log(action.jobApplication)
                return {
                    ...state,
                    jobApplications: newJobApplications
                }
        default:
            return state;
    }
};

export default reducer;
