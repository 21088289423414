import React, { useContext } from 'react';
import { useDrop } from 'react-dnd';
import ApplicationCard from './ApplicationCard';
import LeadCard from './LeadCard';
import { AppContext } from 'store/app.context';
import "./sales-pipeline.css";

const Column = ({ status, applications, leads, onDragEnd }) => {
  const { languageState, leadsState } = useContext(AppContext);

  const [{ isOver }, drop] = useDrop({
    accept: 'LEAD',
    drop: (item) => onDragEnd(item.id, status),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const columnTitle = (status) => {
    switch (status) {
      case 'APPLIED':
        return languageState.translation.COMMON.STATUS_LIST.APPLIED;
      case 'INTRODUCED':
        return languageState.translation.COMMON.STATUS_LIST.INTRODUCED;
      case 'CONNECTED':
        return languageState.translation.COMMON.STATUS_LIST.CONNECTED;
      case 'INTERVIEW_PLANNED':
        return languageState.translation.COMMON.STATUS_LIST.INTERVIEW_PLANNED;
      case 'SELECTED':
        return languageState.translation.COMMON.STATUS_LIST.WON;
      case 'REJECTED':
        return languageState.translation.COMMON.STATUS_LIST.LOST;
      default:
        return '';
    }

  };

  return (
    <div ref={drop} className={`column ${isOver ? 'highlight' : ''}`}>
      <div className="column-header">
        <div className="d-flex justify-content-between gap-1">
          <h4>{columnTitle(status)}</h4>
          <h4>{applications.length + leads?.length || ""}</h4>
        </div>
      </div>
      {applications.map((application) => (
        <ApplicationCard key={application.id} application={application} />
      ))}
      {leads?.map((lead) => (
        <LeadCard key={lead.id} lead={lead} />
      ))}
    </div>
  );
};

export default Column;
