import React, { createContext, useReducer } from 'react';
import CHAT_STATE from './chat';
import CONNECT_STATE from './connect/connect.state';
import DASHBOARD_STATE from './dashboard/dashboard.state';
import HOME_STATE from './home/home.state';
import INVITATION_STATE from './invitations';
import LANGUAGE_STATE from './language';
import LOADER_STATE from './loader';
import MISSION_STATE from './missions/missions.state';
import NOTIFICATION_STATE from './notification';
import PROJECT_STATE from './projects';
import EDUCATIONS_STATE from './settings/educations/educations.state';
import EXPERIENCES_STATE from './settings/experiences/experiences.state';
import LANGUAGES_SETTINGS_STATE from './settings/languages/languages.state';
import POSITIONS_STATE from './settings/positions/positions.state';
import SKILLS_STATE from './settings/skills/skills.state';
import SHARED_STATE from './shared';
import STEPPER_STATE from './stepper';
import SUBSCRIPTIONS_STATE from './subscriptions';
import USER_STATE from './user';
import JOBS_STATE from './jobs/jobs.state';
import APPLICATIONS_STATE from './applications/applications.state';
import { NOTE_STATE } from './notes/note.state';
import POTENTIAL_REVENUE_STATE from './revenue/potential/potential-revenue.state';
import PLANNED_REVENUE_STATE from './revenue/planned/planned-revenue.state';
import LEADS_STATE from './leads/leads.state';


// @ts-ignore
export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [languageState, dispatchChangeLanguage] = useReducer(
    LANGUAGE_STATE.reducer,
    LANGUAGE_STATE.initialState
  );

  const [userState, dispatchUserState] = useReducer(
    USER_STATE.reducer,
    USER_STATE.initialState
  );

  const [subsriptionState, dispatchSubscriptionState] = useReducer(
    SUBSCRIPTIONS_STATE.reducer,
    SUBSCRIPTIONS_STATE.initialState
  )

  const [loaderState, dispatchLoaderState] = useReducer(
    LOADER_STATE.reducer,
    LOADER_STATE.initialState
  );
  const [invitationsState, dispatchInvitationState] = useReducer(
    INVITATION_STATE.reducer,
    INVITATION_STATE.initialState
  );
  const [jobsState, dispatchJobsState] = useReducer(
    JOBS_STATE.reducer,
    JOBS_STATE.initialState
  )

  const [projectState, dispatchProjectState] = useReducer(
    PROJECT_STATE.reducer,
    PROJECT_STATE.initialState
  );

  const [experienceState, dispatchExperienceState] = useReducer(
    EXPERIENCES_STATE.reducer,
    EXPERIENCES_STATE.initialState
  );

  const [educationState, dispatchEducationState] = useReducer(
    EDUCATIONS_STATE.reducer,
    EDUCATIONS_STATE.initialState
  );

  const [skillsState, dispatchSkillsState] = useReducer(
    SKILLS_STATE.reducer,
    SKILLS_STATE.initialState
  );

  const [languageSettingsState, dispatchLanguageSettingsState] = useReducer(
    LANGUAGES_SETTINGS_STATE.reducer,
    LANGUAGES_SETTINGS_STATE.initialState
  );

  const [positionState, dispatchPositionState] = useReducer(
    POSITIONS_STATE.reducer,
    POSITIONS_STATE.initialState
  );

  const [sharedState, dispatchSharedState] = useReducer(
    SHARED_STATE.reducer,
    SHARED_STATE.initialState
  );

  const [connectState, dispatchConnectState] = useReducer(
    CONNECT_STATE.reducer,
    CONNECT_STATE.initialState
  );

  const [missionState, dispatchMissionState] = useReducer(
    MISSION_STATE.reducer,
    MISSION_STATE.initialState
  );

  const [applicationState, dispatchApplicationState] = useReducer(
    APPLICATIONS_STATE.reducer,
    APPLICATIONS_STATE.initialState
  );

  const [chatState, dispatchChatState] = useReducer(
    CHAT_STATE.reducer,
    CHAT_STATE.initialState
  );
  const [homeState, dispatchHomeState] = useReducer(
    HOME_STATE.reducer,
    HOME_STATE.initialState
  );

  const [dashboardState, dispatchDashboardState] = useReducer(
    DASHBOARD_STATE.reducer,
    DASHBOARD_STATE.initialState
  );

  const [notificationsState, dispatchNotificationsState] = useReducer(
    NOTIFICATION_STATE.reducer,
    NOTIFICATION_STATE.initialState
  );

  const [stepperState, dispatchStepperState] = useReducer(
    STEPPER_STATE.reducer,
    STEPPER_STATE.initialState
  );
  const [noteState, dispatchNotesState] = useReducer(
    NOTE_STATE.reducer,
    NOTE_STATE.initialState
  );

  const [totalPotentialRevenueState, dispatchTotalPotentialRevenueState] = useReducer(
    POTENTIAL_REVENUE_STATE.reducer,
    POTENTIAL_REVENUE_STATE.initialState
  );
  const [totalPlannedRevenueState, dispatchTotalPlannedRevenueState] = useReducer(
    PLANNED_REVENUE_STATE.reducer,
    PLANNED_REVENUE_STATE.initialState
);
const [leadsState, dispatchLeadsState] = useReducer(
  LEADS_STATE.reducer,
  LEADS_STATE.initialState
);

  return (
    <
      // @ts-ignore
      AppContext.Provider
      value={{
        languageState,
        userState,
        loaderState,
        jobsState,
        homeState,
        projectState,
        sharedState,
        experienceState,
        educationState,
        skillsState,
        positionState,
        connectState,
        languageSettingsState,
        invitationsState,
        missionState,
        applicationState,
        chatState,
        subsriptionState,
        dashboardState,
        stepperState,
        notificationsState,
        noteState,
        totalPotentialRevenueState,
        totalPlannedRevenueState,
        leadsState,
        dispatchChatState,
        dispatchChangeLanguage,
        dispatchUserState,
        dispatchLoaderState,
        dispatchProjectState,
        dispatchExperienceState,
        dispatchEducationState,
        dispatchSkillsState,
        dispatchSharedState,
        dispatchLanguageSettingsState,
        dispatchPositionState,
        dispatchConnectState,
        dispatchInvitationState,
        dispatchMissionState,
        dispatchHomeState,
        dispatchDashboardState,
        dispatchStepperState,
        dispatchNotificationsState,
        dispatchSubscriptionState,
        dispatchJobsState,
        dispatchApplicationState,
        dispatchNotesState,
        dispatchTotalPotentialRevenueState,
        dispatchTotalPlannedRevenueState,
        dispatchLeadsState
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
