import TYPES from './note.types';

const noteReducer = (state, action) => {
  switch (action.type) {
    case TYPES.ADD_NOTE:
      return {
        ...state,
        note: state.note ? state.note  : [],
      };
      case TYPES.GET_NOTES:
        return {
            ...state,
            note: action.notes,
        };
    default:
      return state;
  }
};

export default noteReducer;
