import CardsHeaderAviability from 'components/cards/card-header-aviability';
import { MODULES } from 'config/core';
import React, { useContext, useEffect } from 'react';
import { AppContext } from 'store/app.context';
import SHARED_STATE from 'store/shared';

const AddOneContact = () => {
    const { dispatchSharedState } = useContext(AppContext);
  
    useEffect(() => {
      dispatchSharedState({
        module: MODULES.CONNECT_YOUR_NETWORK,
        type: SHARED_STATE.types.SET_CURRENT_MODULE,
      });
    }, []);
  return <div>
          <CardsHeaderAviability title="ADD_CONTACT" />

  </div>;
};

export default AddOneContact;
