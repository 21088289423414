import TYPES from './applications.types';

const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.SET_APPLICATIONS:
            return {
                ...state,
                applications: action.applications,
            };
        case TYPES.UPDATE_APPLICATIONS:
            return {
                ...state,
                applications: state.applications.map((application) =>
                    application.id === action.application.id ? action.application : application
                ),
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
