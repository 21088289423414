import { useContext } from 'react';
// @ts-ignore
import useHttpClient from 'services/http-client-hook';
// @ts-ignore
import { AppContext } from 'store/app.context';
// @ts-ignore
import CONNECT_STATE from 'store/connect/connect.state';
import TYPES from 'store/connect/connect.types';

const useConnectService = () => {
  const { httpClient } = useHttpClient();
  // @ts-ignore
  const { connectState, dispatchConnectState } = useContext(AppContext);

  const getAllUsers = async (payload) => {
    const result = await httpClient.get(
      '/network/all-users' + (payload ? payload : ''),
      { params: { limit: 8, page: 1 } }
    );
    if (result && result.status == 200) {
      dispatchConnectState({
        type: CONNECT_STATE.types.GET_ALL_USERS,
        allUsers: result.data.items,
        config: result.data.meta,
      });
    }
  };

  const getAllUsersWithoutLimit = async () => {
    const result = await httpClient.get('/network/all-users');
    if (result && result.status == 200) {
      dispatchConnectState({
        type: CONNECT_STATE.types.GET_ALL_USERS,
        allUsers: result.data.items,
        config: result.data.meta,
      });
    }
  };

  const getConnectedUsers = async (payload) => {
    const result = await httpClient.get(
      '/network/my-network' + (payload ? payload : ''),
      { params: { limit: 8 } }
    );
    if (result && result.status == 200) {
      dispatchConnectState({
        type: CONNECT_STATE.types.GET_CONNECTED_USERS,
        connectedUsers: result.data.items,
        config: result.data.meta,
      });
    }
  };

  const getExperiencesByUser = async (userId) =>
    httpClient.get(`/experience/byUser/${userId}`);

  const getEducationsByUser = async (userId) =>
    httpClient.get(`/education/byUser/${userId}`);

  const getLanguagesByUser = async (userId) =>
    httpClient.get(`/languages/byUser/${userId}`);

  const getUserById = async (userId) => httpClient.get(`users/${userId}`);

  const getAvailibilityByUser = async (userId) =>
    httpClient.get(`/availability/ByUser/${userId}`);

  const getHolidaysByUser = async (userId) =>
    httpClient.get(`/holidays/ByUser/${userId}`);

  const AddNoteToUser = async (userId, note) =>
    httpClient.post(`/notes/${userId}`, { note });

  const GetUserNotes = async (userId) => httpClient.get(`/notes/${userId}`);

  const deleteNote = async (noteId) => httpClient.delete(`/notes/${noteId}`);

  const deleteUserFromMyNetwork = async (userId) =>
    httpClient.delete(`/invitations/delete_friend/${userId}`);

  const getGroups = async () => {
    const result = await httpClient.get(`/groups`);
    if (result && result.status == 200) {
      dispatchConnectState({
        type: CONNECT_STATE.types.GET_ALL_GROUPS,
        groups: result.data,
      });
    }
  };
  const getFavorites = async (payload) => {
    const result = await httpClient.get(
      'network/my-favoritenetwork' + (payload ? payload : ''),
      { params: { limit: 8 } }
    );
    if (result && result.status == 200) {
      dispatchConnectState({
        type: CONNECT_STATE.types.GET_ALL_FAVORITES,
        favorites: result.data.items,
        configFavorites: result.data.meta,
      });
    }
  };

  const addToFavorite = async (userId) =>
    httpClient.patch(`network/addfavorite/user/${userId}`);

  const removeFromfavorite = async (userId) =>
    httpClient.patch(`network/removefavorite/user/${userId}`);

  const addGroup = async (data) => httpClient.post('groups', data);
  const editGroup = async (data) => httpClient.put('groups', data);

  const addUserToGroup = async (groupdId, userId) =>
    httpClient.post(`groups/user/${userId}/group/${groupdId}`);

  const removeUserFromGroup = async (groupdId, userId) =>
    httpClient.delete(`groups/user/${userId}/group/${groupdId}`);

  const getUsersGroup = async (groupdId) => {
    const result = await httpClient.get(`groups/${groupdId}`);
    if (result && result.status == 200) {
      dispatchConnectState({
        type: CONNECT_STATE.types.GET_ALL_GROUP_USERS,
        groupUsers: result.data,
      });
    }
  };

  const archiveGroup = async (groupId) =>
    httpClient.put(`groups/archive/${groupId}`);

  const unarchiveGroup = async (groupId) =>
    httpClient.put(`groups/unarchive/${groupId}`);

  const gellArchives = async () => {
    const result = await httpClient.get(`groups/archive`);
    if (result && result.status == 200) {
      dispatchConnectState({
        type: CONNECT_STATE.types.GET_ALL_ARCHIVE,
        archive: result.data,
      });
    }
  };
  const getAllPublicGroups = async (page) => {
    const result = await httpClient.get(`groups/allgroups/public`, {
      params: { limit: 6, page: page ? page : 1 },
    });
    if (result && result.status == 200) {
      dispatchConnectState({
        type: CONNECT_STATE.types.GET_ALL_GROUPS,
        groups: result.data.items,
        configPublicGroup: result.data.meta,
      });
    }
  };
  const changeGroupType = async (groupId, type) => {
    return httpClient.put(`groups/groupe/${groupId}/type/${type}`);
  };

  const deleteGroup = async (groupId) =>
    httpClient.delete(`groups/group/${groupId}`);

  //**************TAG USERS FEATURE****************//

  const getAllTags = async () => {
    const result = await httpClient.get(`/tags`);
    if (result && result.status === 200) {
      dispatchConnectState({
        type: CONNECT_STATE.types.GET_ALL_TAGS,
        tags: result.data,
      });
    }
  };

  const getUserTags = async () => {
    const result = await httpClient.get(`/tags/my-created-tags`);
    if (result && result.status === 200) {
      dispatchConnectState({
        type: CONNECT_STATE.types.GET_USER_TAGS,
        userTags: result.data,
      });
    }
  };

  const addTagsToUser = async (payload) => httpClient.patch('tags', payload);

  const getTaggedUsers = async () => {
    const result = await httpClient.get(`/users/tagged-users`);
    if (result && result.status === 200) {
      dispatchConnectState({
        type: CONNECT_STATE.types.GET_TAGGED_USERS,
        taggedUsers: result.data,
      });
    }
  };

  const createOwnerTag = async (payload) => httpClient.post('/tags', payload);

  const deleteUserFromTag = async (payload) =>
    httpClient.patch(`/tags/${payload?.tagId}/${payload?.userId}`);

  /** GETTING ALL NETWORK COUNTRIES*/

  const getAllNetworkCountries = async () => {
    const result = await httpClient.get('/address/country');
    if (result && result.status === 200) {
      dispatchConnectState({
        type: TYPES.GET_USERS_COUNTRIES,
        countries: result.data,
      });
    }
  };
  const getAssignedSkills = async () => {
    const result = await httpClient.get('/users/assigned-skills');
    if (result && result.status === 200) {
      dispatchConnectState({
        type: TYPES.GET_ASSIGNED_SKILLS,
        assignedSkills: result.data,
      });
    }
  }
  return {
    getAllUsers,
    getConnectedUsers,
    getExperiencesByUser,
    getEducationsByUser,
    getLanguagesByUser,
    addUserToGroup,
    archiveGroup,
    deleteGroup,
    unarchiveGroup,
    getUserById,
    getGroups,
    gellArchives,
    getFavorites,
    removeFromfavorite,
    addToFavorite,
    getAvailibilityByUser,
    getHolidaysByUser,
    AddNoteToUser,
    getUsersGroup,
    GetUserNotes,
    deleteUserFromMyNetwork,
    addGroup,
    editGroup,
    removeUserFromGroup,
    deleteNote,
    getAllPublicGroups,
    changeGroupType,
    getAllUsersWithoutLimit,
    addTagsToUser,
    getTaggedUsers,
    getAllTags,
    getUserTags,
    createOwnerTag,
    deleteUserFromTag,
    getAllNetworkCountries,
    getAssignedSkills
  };
};

export default useConnectService;
